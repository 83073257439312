<template>
  <div>

    <div v-if="error">
      <p style="color: red;">{{ error }}</p>
    </div>

    <b-modal
      :style="modal_style"
      ref="donation-modal"
      hide-footer
      hide-header
    >

      <div v-if="current_page === 'DONATION' && donation_page">
        <h4 :style="header_style">{{ donation_page.name }}</h4>

        <h5 :style="header_style" v-if="show_select_type" class="mt-16 mb-4 text-center">{{ $t('DONATION.SELECT_DONATION_WAY') }}</h5>
        
        <div v-if="show_select_type" class="top-selection-container mt-4">

          <div v-if="donation_page.fields.activate_one_time">
            <memlist-circular-select-button
              class="fill-button-width"
              v-if="form && form.style"
              :text="$t('DONATION.ONE_TIME')"
              :value="'ONE_TIME'"
              :primary_color="form?.style?.primary_color"
              :secondary_color="form?.style?.secondary_color"
              :hover_color="form?.style?.hover_color"
              :preselected="!donation_page.fields.activate_subscription"
              ref="single_selected_button"
              @selected="single_selected"
            />
          </div>

          <div class="ml-4"></div>

          <div v-if="donation_page.fields.activate_subscription">
            <memlist-circular-select-button
              class="fill-button-width"
              v-if="form && form.style"
              :text="$t('DONATION.SUBSCRIPTION')"
              :value="'SUBSCRIPTION'"
              :primary_color="form?.style?.primary_color"
              :secondary_color="form?.style?.secondary_color"
              :hover_color="form?.style?.hover_color"
              :preselected="true"
              ref="recurring_selected_button"
              @selected="recurring_selected"
            />
          </div>
    
        </div>
    
        <div class="single-selected my-8" v-else-if="show_one_time">
          <p :style="header_style">{{ $t('DONATION.ONE_TIME') }}</p>
        </div>

        <div class="single-selected my-8" v-else-if="show_subscription">
          <p :style="header_style">{{ $t('DONATION.SUBSCRIPTION') }}</p>
        </div>


        <div v-if="has_fixed" class="mt-8">
          <h5 :style="header_style" class="mb-4">{{ $t('DONATION.SELECT_FIXED') }}</h5>
          <div class="top-selection-container">

            <div
              
              v-for="(option, index) in items"
              :key="option.id"
              :class="{ selected: selected_option === option.amount }">

              <memlist-circular-select-button
                class="fill-button-width ml-2 mr-2"
                v-if="form && form.style"
                :text="`${option.amount} kr`"
                :value="`${option.amount}`"
                :primary_color="form?.style?.primary_color"
                :secondary_color="form?.style?.secondary_color"
                :hover_color="form?.style?.hover_color"
                :preselected="false"
                ref="amount_select_button"
                @selected="amount_selected(option.amount)"
              />
              

            </div>
            
          </div>

        </div>
        
        <div v-if="has_custom" class="mt-8">
          
          <h5 :style="header_style" class="mt-4 mb-4" v-if="has_fixed">{{ $t('DONATION.SELECT_CUSTOM') }}</h5>
          <div class="custom-container">
            <memlist-circular-input-select-button
              class="fill-button-width"
              v-if="form && form.style"
              :currency="'kr'"
              :text="''"
              :value="0"
              :primary_color="form?.style?.primary_color"
              :secondary_color="form?.style?.secondary_color"
              :hover_color="form?.style?.hover_color"
              :preselected="false"
              ref="custom_amount_button"
              @changed="custom_amount_changed"
            />

            <p v-if="invalid_custom_amount" class="mt-4" style="color: red;">{{ $t('DONATION.INVALID_MIN_AMOUNT', { min_amount: custom_min_amount }) }}</p>
          </div>
          
        </div>

        <div class="center-container">
          <h5 :style="header_style" class="mt-4 mb-4" v-if="has_fixed">{{ $t('DONATION.SELECT_PAYMENT') }}</h5>
          <div class="custom-container">
            <memlist-segmented-select-button 
              :parts="[{ text: 'Swish', value: 'SWISH' }, { text: 'Kort', value: 'STRIPE' }, { text: 'Faktura', value: 'INVOICE' }, { text: 'Autogiro', value: 'AUTOGIRO' }]"
              :primary_color="form?.style?.primary_color"
              :secondary_color="form?.style?.secondary_color"
              :hover_color="form?.style?.hover_color"
              @selected="payment_selected"
            />
          </div>
          
        </div>
        
      </div>
      

      <div v-else-if="current_page === 'PERSON_DETAILS'">

        <h5 :style="header_style" class="mt-4 mb-4">{{ $t('DONATION.PERSONAL_DETAILS') }}</h5>
        
        <FormPagePersonDetails
          :show_buttons="false"
          :form_page="person_details_page"
          @updated="person_details_updated"
        />
      </div>

      <div v-else-if="current_page === 'CONFIRM'">
        <FormPageConfirm
          :form_page="confirm_page"
        />
      </div>

      <div v-else-if="current_page === 'PAYMENT'">
        <SelectPaymentPage
          v-if="shop_order"
          :is_donation="true"
          :payment_method="payment_method"
          :personal_details="person_details_data"
          :prop_shop_order="shop_order"
        />
        <div class="d-flex justify-content-center mb-3" v-else>
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
      
      

      <div class="center-container mt-16">

        <div class="custom-container">
      
        <memlist-circular-button
          class="fill-button-width"
          v-if="form && form.style && current_page !== 'PAYMENT'"
          :text="$t('DONATION.CONTINUE')"
          :primary_color="form?.style?.primary_color"
          :secondary_color="form?.style?.secondary_color"
          :hover_color="form?.style?.hover_color"
          ref="continue_button"
          @click="continue_page(0)"
        />

        <p v-if="invalid_form" style="margin-top: 16px;">{{ invalid_form }}</p>

        </div>
        
      </div>


    </b-modal>
    
    <div class="center-container" style="margin-top: 64px;">

        
      <memlist-circular-button
        class="fill-button-width"
        v-if="form && form.style"
        :text="form?.texts?.button_text || 'Donera'"
        :primary_color="form?.style?.primary_color"
        :secondary_color="form?.style?.secondary_color"
        :hover_color="form?.style?.hover_color"
        @click="$refs['donation-modal'].show()"
      />

      <div class="d-flex justify-content-center mb-3" v-else>
        <b-spinner label="Loading..."></b-spinner>
      </div>

    </div>
    
  </div>
</template>

<script>
import axios from 'axios';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import StripePaymentLinkComponent from './StripePaymentLinkComponent.vue';
import FormPagePersonDetails from '@/view/pages/public/forms/pages/FormPagePersonDetails.vue';
import FormPageConfirm from '@/view/pages/public/forms/pages/FormPageConfirm.vue';
import SelectPaymentPage from '@/view/pages/members/payment/SelectPaymentPage.vue';


export default {
  name: 'TestDonationPopup',
  props: [],
  mixins: [ toasts ],
  components: {
    StripePaymentLinkComponent,
    FormPagePersonDetails,
    FormPageConfirm,
    SelectPaymentPage,
  },
  computed: {

    calculated_amount() {

      if (this.selected_option !== 'custom') {
        const amount = parseInt(this.selected_option+'');

        if (isNaN(amount)) {
          return 0;
        }

        return amount;
      }

      return parseInt(this.input_amount+'');
    },

    first_button_ok() {
      if (this.selected_option !== 'custom') {
        const amount = parseInt(this.selected_option+'');

        if (isNaN(amount)) {
          return false;
        }

        return true;
      }

      return !this.invalid_custom_amount;
    },

    invalid_custom_amount() {
      if (this.selected_option !== 'custom') {
        return false;
      }

      return parseInt(this.input_amount+'') < parseInt(this.custom_min_amount+'');
    },

    custom_min_amount() {
      return this.custom.min_amount;
    },

    modal_style() {
      return {
        backgroundColor: this.form?.style?.bg || 'white'
      };
    },

    header_style() {
      return {
        width: '100%',
        color: this.form?.style?.primary_color || 'black',
        textAlign: 'center'
      }
    },

    show_select_type() {

      if (!this.donation_page) {
        return false;
      }

      return this.donation_page.fields.activate_one_time && this.donation_page.fields.activate_subscription;
    },

    show_one_time() {
      if (!this.donation_page) {
        return false;
      }

      return this.donation_page.fields.activate_one_time;
    },
    
    show_subscription() {
      if (!this.donation_page) {
        return false;
      }

      return this.donation_page.fields.activate_subscription;
    },
    
  },
  data() {
    return {

      invalid_form: null,

      linkstr: null,

      shop_order: null,

      error: null,

      payment_method: null, /// SWISH, INVOICE, AUTOGIRO, STRIPE

      current_page: 'DONATION',

      selected_type: 'ONE_TIME', /// ONE_TIME, SUBSCRIPTION

      input_amount: null,

      form: null,

      items: [],

      custom: {},

      has_fixed: false,
      has_custom: false,

      selected_option: null,

      person_details_page: null,
      person_details_data: null,
      
      confirm_page: null,

      donation_page: null,
    };
  },
  async mounted() {

    this.linkstr = this.$route.params.linkstr;

    this.form = await this.load_form(this.linkstr);

    this.setup_page();
  },
  watch: {
    
  },
  beforeDestroy() {
    
  },
  methods: {

    setup_person_details_page_from_payment(is_subscription, payment_method) {

      // if there is no subscription, then we use the person details that the admins have enabled (if any)
      if (!is_subscription) {
        console.log('not subscription');
        return;
      }

      // when selecting a payment we need to make sure we get the appropriate data of the payer
      // this is only relevant if using subscriptions/recurring payments

      if (!this.person_details_page) {
        this.person_details_page = {
          name: 'details',
          type: 'PERSON_DETAILS',
          index: 1,
          fields: {}
        }
      }

      switch (payment_method) {
        case 'SWISH': {
          this.person_details_page.fields.phone = { show: true, required: true };
          break;
        }
        case 'STRIPE': {
          this.person_details_page.fields.email = { show: true, required: true };
          break;
        }
        case 'INVOICE': {
          this.person_details_page.fields.firstname = { show: true, required: true };
          this.person_details_page.fields.lastname = { show: true, required: true };
          this.person_details_page.fields.address = { show: true, required: true };
          this.person_details_page.fields.zipcode = { show: true, required: true };
          this.person_details_page.fields.post = { show: true, required: true };
          this.person_details_page.fields.co = { show: true, required: true };
          break;
        }
        case 'AUTOGIRO': {
          this.person_details_page.fields.in_personnr = { show: true, required: true };
          this.person_details_page.fields.firstname = { show: true, required: true };
          this.person_details_page.fields.lastname = { show: true, required: true };
          break;
        }
      }

      this.person_details_page = { ...this.person_details_page };

      console.log('person details page', this.person_details_page);
    },

    set_detail_prop(fields, property, show, required) {
      if (!fields[property]) {
        fields[property] = { show, required };
      }
    },

    payment_selected(value) {
      console.log('payment selected', value);

      this.payment_method = value;
    },
    
    custom_amount_changed(value) {
      const buttons = this.$refs['amount_select_button'];

      for (const button of buttons) {
        if (button.get_value() === value) {
          continue;
        }

        button.reset();
      }

      this.input_amount = value;
      this.selected_option = 'custom';
    },

    amount_selected(value) {

      const buttons = this.$refs['amount_select_button'];

      if (this.$refs['custom_amount_button']) {
        this.$refs['custom_amount_button'].reset();
      }

      for (const button of buttons) {
        if (button.get_value()+'' === value+'') {
          continue;
        }

        button.reset();
      }

      this.selected_option = value;
    },

    single_selected(value) {
      this.$refs['recurring_selected_button'].reset();
      this.selected_type = value;
    },

    recurring_selected(value) {
      this.$refs['single_selected_button'].reset();
      this.selected_type = value;
    },
    
    button_selected(value) {
      console.log('testbutton selected', value);
    },

    person_details_updated(data) {
      this.person_details_data = data;
    },

    async load_form(linkstr) {
      const res = await axios.get(`/form/${linkstr}`);

      if (res.status === 200) {
        console.log('data', res.data);

        return res.data;
      }

      return null;
    },
    
    /**
     * This will validate the current page, if valid, it will switch to the next page needed
     * 
     If recurring/subscription: 
      1. If swish: We need to get the swish number
      2. If stripe: We need the email
      3. If autogiro: We need personnummer, name, address
      4. If invoice: We need name, address
      
     If one time/single, we only ask for information if details was setup in the administration page.
     */
    continue_page(index) {

      if (index === 0) {
        if (!this.first_button_ok) {
          this.invalid_form = this.$t('DONATION.INVALID_FORM');
          return;
        }
      }

      if (!this.payment_method) {
        this.invalid_form = this.$t('DONATION.SELECT_PAYMENT_METHOD');
        return;
      }

      this.invalid_form = null;

      console.log('type', this.selected_type);

      this.setup_person_details_page_from_payment(this.selected_type === 'SUBSCRIPTION', this.payment_method);

      if (this.current_page === 'DONATION') {
        if (this.person_details_page) {
          this.current_page = 'PERSON_DETAILS'
        }
        else if (this.confirm_page) {
          this.current_page = 'CONFIRM';
        }
        else {
          this.current_page = 'PAYMENT';
        }
      }
      else if (this.current_page === 'PERSON_DETAILS') {
        if (this.confirm_page) {
          this.current_page = 'CONFIRM';
        }
        else {
          this.current_page = 'PAYMENT';
        }
      }
      else if (this.current_page === 'CONFIRM') {
        this.current_page = 'PAYMENT';
      }

      if (this.current_page === 'PAYMENT') {
        this.create_order_if_not_exists();
      }
    },

    async create_order_if_not_exists() {
      if (this.shop_order) {
        return;
      }

      const data = [];

      data.push({
        type: 'DONATION',
        amount: this.selected_option === 'custom' ? this.input_amount : parseInt(this.selected_option+''),
        selected_type: this.selected_type,
        selected_option: this.selected_option,
      });

      if (this.person_details_page) {
        this.person_details_data.type = 'PERSON_DETAILS';
        data.push(this.person_details_data);
      }

      const res = await axios.post(`/form/public/${this.linkstr}`, {
        form_data: data,
        visitor_id: this.form.visitor_id
      });

      if (res.status === 201) {
        this.shop_order = res.data.shop_order;
        this.current_page = 'PAYMENT';

        
      }
      else {
        console.error('unable to create shop order');
        this.error = 'UNABLE_TO_CREATE_ORDER';
      }
    },

    setup_page() {

      this.donation_page = this.form.pages.find(item => item.type === 'DONATION');

      if (!this.donation_page) {
        this.error = 'DONATION_PAGE_ERROR_NOT_FOUND';
        return;
      }

      this.items = this.donation_page.fields.items.filter(item => item.type === 'FIXED');

      this.has_fixed = this.items.length > 0;

      this.custom = this.donation_page.fields.items.find(item => item.type === 'CUSTOM');

      this.has_custom = !!this.custom;

      this.person_details_page = this.form.pages.find(item => item.type === 'PERSON_DETAILS');

    },
  }
};
</script>


<style lang="css" scoped>

.custom-container {
  display: flex;
  flex-direction: column;  /* Stacks items vertically */
  align-items: center;     /* Centers items horizontally */
  justify-content: center; /* Centers items vertically within the container if needed */
}

.top-selection-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fill-button-width {
  height: 46px;
  min-width: 140px;
}

.center-container {
  margin-top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.single-selected {
  display: flex;
  align-items: center;
  padding: 8px;
}

.single-selected p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}

h4 {
  text-align: center;
}

h5 {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
}

/* CSS Code */
.radio-button-group {
  display: flex;
  flex-direction: column;
}

.radio-button {
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid #ccc; /* Default border color is gray */
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #fff; /* Default background color */
  transition: background-color 0.2s, border-color 0.2s;
}

.radio-button:hover {
  background-color: #f9f9f9;
}

.radio-button input[type="radio"] {
  display: none;
}

.radio-custom {
  width: 22px;
  height: 22px;
  border: 2px solid #ccc; /* Match the button's border color */
  border-radius: 50%;
  position: relative;
  margin-right: 12px;
  transition: border-color 0.2s;
}

.radio-custom::after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #0085eb;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  opacity: 0;
  transition: opacity 0.2s;
}

.radio-label {
  font-size: 16px;
  transition: color 0.2s;
}

input[type="radio"] {
  display: none;
}

/* Styles when the radio button is selected */
input[type="radio"]:checked + .radio-button {
  background-color: #e6f0ff; /* Highlighted background color */
  border-color: #0085eb;     /* Highlighted border color */
}

input[type="radio"]:checked + .radio-button .radio-custom {
  border-color: #0085eb; /* Change radio indicator border color */
}

input[type="radio"]:checked + .radio-button .radio-custom::after {
  opacity: 1;
}

input[type="radio"]:checked + .radio-button .radio-label {
  font-weight: bold;
  color: #005bb5;
}

.amount-input {
  width: 100%;
}

.amount-input input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.amount-input input:focus {
  outline: none;
  border-color: #0085eb;
  box-shadow: 0 0 5px rgba(0, 133, 235, 0.5);
}

.custom_amount {
  font-weight: 400;
}

</style>